import React from "react";

class Home extends React.Component {
    render(){
        return(
            <div className="homeCont home">
                <div className="headerBlock">
                    <div className="homeMainText">Under Maintenace</div>
                </div>
            </div>
        );
    }
}

export default Home;
