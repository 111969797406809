import './style/App.css';
import Home from './components/Home';
class App {

  render() {
    return (
            <Home/>
    );  
  }
  
}

export default App;
